import NoProfilePicture from '../assets/no-profile-picture.png';

export type ConfType = {
  downloadPDFAndCSV: boolean;
  askIsCardLostOrStolen: boolean;
  callCenterNumber: string;
  internationalCallCenterNumber: string;
  supportEmail: string;
  routesBlackList: string[];
  googlePlay?: string;
  appStore?: string;
  appName: string;
  privacyPolicyLink?: string;
  webAppLink?: string;
  defaultCurrencyConf?: DefaultCurrencyType;
  authStrategy: 'access-token' | 'refresh-token';
  currencyFirst?: boolean;
  profileDefaultImage?: any;
  switchBalanceFormatterSeparatorCommaDot?: boolean;
  psd2: boolean;
  hideReportPreview?: boolean;
  supporterMode?: boolean;
  googleAnalytics?: boolean;
  firstDayOfWeekMonday: boolean;
  multipleTabsSession: boolean;
};

interface DefaultCurrencyType {
  currencyCode: string;
  symbol: string;
  name: string;
  image?: any;
}
export type ConfItemType = {
  [key in keyof ConfType]?: ConfType[key];
};

const configuration: ConfType = {
  downloadPDFAndCSV: true,
  askIsCardLostOrStolen: true,
  callCenterNumber: '+44 (0) 203 773 1922',
  internationalCallCenterNumber: '+44 (0) 203 773 1922',
  supportEmail: 'info@pannovate.com',
  routesBlackList: [],
  googlePlay: 'https://play.google.com/store/apps',
  appStore: 'https://www.apple.com/app-store/',
  appName: 'Pannovate',
  authStrategy: 'refresh-token',
  currencyFirst: false,
  profileDefaultImage: NoProfilePicture,
  switchBalanceFormatterSeparatorCommaDot: false,
  psd2: false,
  hideReportPreview: false,
  supporterMode: false,
  googleAnalytics: false,
  firstDayOfWeekMonday: true,
  multipleTabsSession: false
};

export default configuration;
